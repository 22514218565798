import {createApp, reactive} from 'vue/dist/vue.esm-bundler';
import VueMatomo from 'vue-matomo';

// modules
import config from './modules/config';
import router from './modules/router';
import {isEmpty, formatComma, formatDate} from './modules/tools';

// components
import header from './components/header';
import footer from './components/footer';
import StageMap from './components/stageMap';
import Loader from './components/loader';

const app = createApp({template: require('./pages/_layout.html')});

const filters = {
	capitalize: (string) => {
		const capitalized = [];
		string.split(' ').forEach(word => {
			capitalized.push(
					word.charAt(0).toUpperCase() +
					word.slice(1).toLowerCase()
					);
		});
		return capitalized.join(' ');
	},
	uppercase: (string) => {
		return string.toUpperCase();
	},
};

// vue globals
app.config.globalProperties.Config = config;
app.config.globalProperties.isEmpty = isEmpty;
app.config.globalProperties.$capitalize = filters.capitalize;
app.config.globalProperties.$formatComma = formatComma;
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.$uppercase = filters.uppercase;
app.config.globalProperties.$live = reactive({
	centeredMarkerId: null,
	centeredTrackerId: null,
	trackerPositions: [],
	count: 0,
});

app.use(router);

if (config.isProd === true) {
	app.use(VueMatomo, {
		host: 'https://stats.matsport.com',
		siteId: 7,
		router,
	});
}

app.component('app-header', header());
app.component('app-footer', footer());
app.component('cyc-stage-map', StageMap(require('/src/components/stageMap.html')));
app.component('cyc-loader', Loader());

app.mount('#app');
