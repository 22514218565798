import Config from './config';

const URL = Config.serverTracker;

export default {
	async getCompetitions(query = {}) {
		const request = await window.fetch(`${URL}/api/competitions?query=${encodeURI(JSON.stringify({query}))}`);
		return await request.json();
	},
	async getCompetition(id) {
		const request = await window.fetch(`${URL}/api/competitions/${id}`);
		return await request.json();
	},
	async getStage(id) {
		const request = await window.fetch(`${URL}/api/stages/${id}`);
		return await request.json();
	},
	async getTracker(id) {
		const request = await window.fetch(`${URL}/api/positions/${id}`);
		return await request.json();
	},
	async getTrackers(aids) {
		const request = await window.fetch(`${URL}/api/group`, {
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({aids}),
		});
		return await request.json();
	},
	async checkCompetitionPass(competitionId, password) {
		const request = await window.fetch(`${URL}/api/check/${competitionId}`, {
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({password}),
		});
		return await request.json();
	},
};
