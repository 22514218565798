import API from '../modules/API';
import Config from '../modules/config';

export default () => {
	return {
		template: require('/src/pages/home.html'),
		data() {
			return {
				competitions: null,
				competitionsError: null,
				competitionsLoading: false,
			};
		},
		created() {
			this.getCompetitions();
		},
		mounted() {
			document.title = `Accueil - ${Config.appName}`;
		},
		methods: {
			async getCompetitions() {
				this.competitionsError = null;
				this.competitionsLoading = false;

				const query = {
					$select: ['id', 'name', 'startDate', 'endDate', 'tracking'],
					tracking: true,
					endDate: {
						$gte: this.$formatDate(new Date()),
					},
				};
				
				try {
					this.competitionsLoading = true;
					this.competitions = await API.getCompetitions(query);
				} catch (exception) {
					this.competitionsError = exception;
				} finally {
					this.competitionsLoading = false;
				}
			},
			goToCompetition(ev) {
				this.$router.push(`/competitions/${ev.currentTarget.value}`);
			},
		},
	};
};
